import React from "react"
import PropTypes from "prop-types"

import { StyledTickListItem } from "./tick-lists-styles"

const TickListItem = ({ listItem }) => {
  return <StyledTickListItem>{listItem}</StyledTickListItem>
}

TickListItem.propTypes = {
  listItem: PropTypes.string.isRequired,
}

export { TickListItem }
