import React from "react"
import PropTypes from "prop-types"

import IndexHeader from "./index-header"
import PageHeader from "./page-header"

export const Header = ({ pathname, title, subTitle, hideTemplateParts }) => {
  switch (pathname) {
    case "/":
      return <IndexHeader />

    case "/about/":
    case "/services/":
    case "/industries/":
    case "/contact-us/":
    case "/legal/":
    case "/engagements/":
      return (
        <PageHeader
          title={title}
          subTitle={subTitle}
          hideTemplateParts={hideTemplateParts}
        />
      )

    default:
      return null
    // return (
    //   <PageHeader
    //     title={title}
    //     subTitle={subTitle}
    //     hideTemplateParts={hideTemplateParts}
    //   />
    // )
  }
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
  hideTemplateParts: PropTypes.string,
}
