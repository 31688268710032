import React from "react"
import PropTypes from "prop-types"

import StyledAvatarImage, {
  StyledTeam,
  StyledTeamMember,
  StyledTeamContainer,
} from "./team-styles"
import { Container } from "../../components"

/**
 * Single team member
 * @param avatarURL URL to avatar image
 * @param description Little description of team member
 */

const TeamMember = ({ avatarURL, description, name }) => {
  return (
    <StyledTeamMember>
      <StyledAvatarImage fluid={avatarURL.childImageSharp.fluid} />
      <p className="max-width-800">{description}</p>
    </StyledTeamMember>
  )
}

/**
 * Teams information
 * @param teams Object holding configuration for teams
 */

const Team = ({ teams }) => {
  return (
    <StyledTeam>
      <Container>
        <h2>Meet The Team</h2>
        <StyledTeamContainer>
          {teams.map(team => (
            <TeamMember
              avatarURL={team.avatarURL}
              description={team.description}
              name={team.name}
            />
          ))}
        </StyledTeamContainer>
      </Container>
    </StyledTeam>
  )
}

Team.propTypes = {
  teams: PropTypes.shape({
    avatarURL: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedTeam: PropTypes.any.isRequired,
  setSelectedTeam: PropTypes.any.isRequired,
}

TeamMember.propTypes = {
  avatarURL: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export { Team, TeamMember }
