import React from "react"
import Fade from "react-reveal/Fade"

import { Container, LVLink } from "../"
import StyledCTA, { StyledCTASection } from "./cta-styles"

/**
 * Call to action region
 */

const CTA = () => {
  return (
    <StyledCTASection>
      <Fade bottom duration={250} delay={200}>
        <Container className="centered">
          <span className="text-caption">Get Started</span>
          <StyledCTA className="max-width-90">
            Join hundreds of Entrepreneurs, Business Owners, Individuals and
            Government bodies all over the world, reduce frictions and operate
            more efficiently.
          </StyledCTA>
          <LVLink to="/contact-us/">Contact Us</LVLink>
          <LVLink to="/services/" variant="secondary">
            See Services
          </LVLink>
        </Container>
      </Fade>
    </StyledCTASection>
  )
}

export { CTA }
