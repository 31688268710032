import styled from "@emotion/styled"

import { StyledControl } from "../input/input-styles"

const StyledTextarea = styled.textarea`
  ${StyledControl};
  width: 100%;
  resize: none;
`

export default StyledTextarea
