import styled from "@emotion/styled"

import CheckIcon from "../../images/icons/check.svg"

export const StyledTickLists = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0;
  align-items: flex-start;

  @media (min-width: 759px) {
    flex-direction: row;
  }
`

export const StyledTickListItem = styled.li`
  position: relative;
  flex-basis: 50%;
  list-style-type: none;
  z-index: 2;
  margin: 0 0 var(--spacing-md) 0;
  padding: 0 0 var(--spacing-xxs) 2em;

  &:before {
    position: absolute;
    content: "";
    background-color: #d6e7ff;
    display: block;
    height: 100%;
    width: 2.6em;
    left: 0;
    top: 0;
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: "";
    background-image: url(${CheckIcon});
    display: block;
    background-repeat: no-repeat;
    width: 2.8em;
    margin-left: var(--spacing-xxs);
    height: 2.6em;
    left: 0;
    top: 0;
    z-index: 4;
  }
`
