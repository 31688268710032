import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import { StyledClient } from "./client-styles"

/**
 * Renders single logo of client
 * @param imageURL Gatsby URL of image
 * @param clientName Name of Client
 */

const Client = ({ logo, clientName }) => {
  return (
    <StyledClient title={clientName}>
      <Img fluid={logo.childImageSharp.fluid} alt={clientName} />
    </StyledClient>
  )
}

Client.propType = {
  logo: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
}

export default Client
