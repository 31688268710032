import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"

import { spacing } from "../styles/theme"
import { StyledLVLink } from "../link/link-styles"
import StyledContainer from "../container/container-styles"

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
`

const StyledNav = styled.nav`
  padding: ${() => `${spacing.spacing_md}px 0 ${spacing.spacing_xl}px`};
  background: inherit;

  .hamburger {
    height: 20px;
    width: 30px;
    border: none;
    background: inherit;
    cursor: pointer;
    position: relative;
  }

  .second,
  .first {
    height: 3px;
    display: block;
    width: 100%;
    background: #000;
    position: absolute;
    left: 0;
  }
  .first {
    top: 3px;
  }
  .second {
    bottom: 3px;
  }
  .nav-container {
    display: flex;
    align-items: center;

    .logo {
      flex: 1;
    }
  }

  a:not(${StyledLVLink}) {
    text-decoration: none;
    margin-right: 1em;
    font-family: poppins;
    color: #000;
  }

  .active-link {
    position: relative;
    z-index: 1;
  }

  .active-link:after {
    position: absolute;
    content: "";
    top: -15px;
    left: 0;
    height: 4px;
    width: 100%;
    background: var(--lightville-blue);
    display: block;
    z-index: -1;
  }

  .desktop-links {
    display: none;
  }

  @media screen and (min-width: 759px) {
    .hamburger {
      display: none;
    }
    .desktop-links {
      display: block;
    }
  }
`

export const StyledMobileNav = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  z-index: 100;
  background: #fff;

  animation: ${fadeIn} 0.25s;

  ${StyledContainer} {
    height: 100vh;
  }

  font-family: poppins;

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 1em;
    font-size: 1.2em;
  }

  a {
    text-decoration: none;
    display: block;
  }

  .mobile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  a:not(.active) {
    color: #000;
    position: relative;
  }

  a.mobile-active:not(.active)::before,
  a.mobile-active:not(.active)::after,
  a:not(.active):hover::before,
  a:not(.active):hover::after {
    content: "";
    position: absolute;
    display: block;
    height: 2px;
    width: 18px;
    background: var(--lightville-blue);
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }

  a.mobile-active:not(.active),
  a::not(.active):hover {
    color: var(--lightville-blue);
  }

  a.mobile-active:not(.active)::before,
  a::not(.active):hover::before {
    left: 0;
  }
  a.mobile-active:not(.active)::after,
  a::not(.active):hover::after {
    right: 0;
  }
  a.active {
    margin: 0;
  }

  button {
    background: inherit;
    display: inline-flex;
    border: 1px solid #ccc;

    img {
      margin: auto;
    }
  }
`

export default StyledNav
