import React from "react"
import PropTypes from "prop-types"

import StyledSection from "./section-styles"

/**
 * Wraps content with top and bottom spacing
 * @param children Component's children
 * @param background Specifies if component has background
 */

const Section = ({ children, background, ...props }) => {
  return (
    <StyledSection background={background} {...props}>
      {children}
    </StyledSection>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.bool,
}

export { Section }
