import styled from "@emotion/styled"

const StyledClients = styled.section`
  position: relative;
  z-index: 3;
  max-width: 22em;
  margin: 0 auto;
  margin-top: calc(-1 * calc(var(--spacing-xl) + 12px));

  .clients-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (min-width: 759px) {
    max-width: 800px;
    margin-top: calc(-1 * calc(var(--spacing-xl) + 20px));

    .centered {
      font-size: var(--mini);
    }
  }

  @media (min-width: 1024px) {
    margin-top: calc(-1 * calc(var(--spacing-xxl)));
  }
`

export const StyledClient = styled.div`
  flex: 0 0 4.3em;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 0 0.2em 1em;

  img {
    margin: auto;
  }
`

export default StyledClients
