import React, { useContext } from "react"

import StyledNav from "./nav-styles"
import { Logo, Container, LVLink } from "../"
import { MenuContext } from "../layout/layout"

export const Nav = () => {
  const menuContext = useContext(MenuContext)
  const { openModal } = menuContext
  return (
    <StyledNav>
      <Container>
        <div className="nav-container">
          <div className="logo">
            <Logo link variant="vanilla" className="logo-image" />
          </div>

          <div className="desktop-links">
            <LVLink
              to="/industries/"
              variant="vanilla"
              activeClassName="active-link"
            >
              Industries
            </LVLink>
            <LVLink
              to="/services/"
              variant="vanilla"
              activeClassName="active-link"
            >
              Services
            </LVLink>
            {/* <LVLink to="/faq" variant="vanilla" activeClassName="active-link">
              FAQs
            </LVLink> */}

            <LVLink
              to="/engagements/"
              variant="vanilla"
              activeClassName="active-link"
            >
              Engagements
            </LVLink>
            <LVLink
              to="/about/"
              variant="vanilla"
              activeClassName="active-link"
            >
              About Us
            </LVLink>
          </div>
          <LVLink to="/contact-us/">Contact Us</LVLink>
          <button className="hamburger" onClick={openModal}>
            <span className="first"></span>
            <span className="second"></span>
          </button>
        </div>
      </Container>
    </StyledNav>
  )
}
