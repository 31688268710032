import styled from "@emotion/styled"
import StyledSection from "../../components/section/section-styles"

const StyledTestimonial = styled(StyledSection)`
  text-align: center;
  position: relative;
  padding: 4em 1em;

  .testimony {
    font-family: Poppins;
    font-size: 1em;
  }

  .testifier {
    font-style: italic;
    font-weight: 700;
    transition: all 0.25s;
  }

  .prev,
  .next {
    position: absolute;
    left: 0;
    display: block;
    transform: translateY(-50%);
    top: 50%;
    background: var(--lightville-blue);
    color: #fff;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    cursor: pointer;
    display: none;
    font-size: var(--zeta);

    img {
      height: 20px;
    }
  }
  .navigators .active {
    background: var(--lightville-blue);
  }
  .navigators button {
    height: 6px;
    width: 2.75em;
    background: #edf0f5;
    cursor: pointer;
    margin-right: var(--spacing-xxs);
    border: 0;
    border-radius: 2px;
  }
  .next {
    left: auto;
    right: 0;
  }

  @media (min-width: 759px) {
    .testimony {
      font-size: var(--epsilon);
      max-width: 600px;
      margin-right: auto;
      margin-left: auto;
    }
    .next,
    .prev {
      display: block;
    }
  }

  @media (min-width: 999px) {
    .testimony {
      font-size: var(--delta);
    }
  }
  @media (min-width: 1024px) {
    .testimony {
      max-width: 1024px;
    }
  }
`

export default StyledTestimonial
