/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect, createContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AOS from "aos"

import MobileNav from "../../components/nav/mobile-nav"
import Footer from "../footer/footer"
import { Header, CTA } from "../"
import "../layout.css"
import "aos/dist/aos.css"

/**
 * Wraps a page to provide constant elements like Footer, Header, SEO, etc
 * @param {ReactNode} children Element Root to render inside Layout
 * @param location React Router Location object for determining current page
 */

export const MenuContext = createContext()

const Layout = ({ children, location, title, subTitle, hideTemplateParts }) => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  })

  const { pathname } = location
  const [menuOpen, setMenuOpen] = useState(false)

  const closeModal = () => {
    setMenuOpen(false)
  }

  const openModal = () => {
    setMenuOpen(true)
  }

  const menuContextValue = {
    menuOpen,
    closeModal,
    openModal,
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MenuContext.Provider value={menuContextValue}>
      {menuOpen ? (
        <MobileNav />
      ) : (
        <>
          <Header
            hideTemplateParts={hideTemplateParts}
            siteTitle={data.site.siteMetadata.title}
            pathname={pathname}
            title={title}
            subTitle={subTitle}
          />
          <main>{children}</main>
          {!hideTemplateParts && <CTA />}
          {!hideTemplateParts && <Footer />}
        </>
      )}
    </MenuContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  hideTemplateParts: PropTypes.bool,
}

export { Layout }
