import styled from "@emotion/styled"

import StyledSection from "../section/section-styles"

const StyledCTA = styled.h2`
  line-height: 1.5;
  font-weight: 400;
  font-size: 1.25rem;
  margin-bottom: var(--spacing-sm);

  @media screen and (min-width: 759px) {
    font-size: var(--beta);
    line-height: 1.3;
  }
`

export const StyledCTASection = styled(StyledSection)`
  background: var(--lightville-blue-lighter);
  padding: var(--spacing-xl) 0;

  .text-caption {
    display: block;
    margin-bottom: var(--spacing-xs);
  }

  @media screen and (min-width: 759px) {
    padding: var(--spacing-xxl) 0;
  }
`

export default StyledCTA
