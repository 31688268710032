import React from "react"
import PropTypes from "prop-types"

import { TickListItem } from "./tick-list-item"
import { StyledTickLists } from "./tick-lists-styles"

const TickList = ({ listItems }) => {
  return (
    <StyledTickLists>
      {listItems.map(listItem => (
        <TickListItem listItem={listItem} />
      ))}
    </StyledTickLists>
  )
}

TickList.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export { TickList }
