import React from "react"
import PropTypes from "prop-types"

import StyledTextarea from "./textarea-styles"

/**
 * Renders a textarea element
 * @param children Text to be rendered inside of textarea
 * @param placeholder Placeholder for textarea
 * @param onChange Function to run when the input value changes
 * @param value Current value of the textarea
 * @param row Number of rows before hiding overflow
 */
const Textarea = ({
  children,
  placeholder,
  onChange,
  value,
  rows,
  ...props
}) => {
  return (
    <StyledTextarea
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      rows={rows}
      {...props}
    >
      {children}
    </StyledTextarea>
  )
}

Textarea.propTypes = {
  children: PropTypes.element,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
}

export { Textarea }
