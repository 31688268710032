import React from "react"

import StyledFlex from "./flex-styles"

const Flex = ({ children, ...props }) => {
  return <StyledFlex {...props}>{children}</StyledFlex>
}

Flex.Child = <p>FlexChild</p>

export { Flex }
