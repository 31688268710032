import React, { useState } from "react"

import StyledTestimonial from "./testimonial-styles"
import { Container } from "../container/container"
import ChevronLeft from "../../images/icons/chevron-left.svg"
import ChevronRight from "../../images/icons/chevron-right.svg"

const testimonies = [
  {
    id: 0,
    position: "Executive Director, EyesonDubai",
    testimony: `LightVille was simply our bail out. They helped us improve our
    experience to clients and meet our market's demand. With their
    efforts, we reached our corporate goal. They have ever since, remained
    our Brand's strategic partners`,
  },
  {
    id: 1,
    position: "MD/CEO Multiset Technologies",
    testimony:
      "As a technology firm, we have received the best advisory service from LightVille. With their advice, we've been able to maintain a trajectory of improvement, which has given us a scalable advantage. There is always this extra touch to their interactions with us.",
  },
  {
    id: 2,
    position: "Chairman, Board of Coinage Trust International Ltd",
    testimony:
      "LightVille is an asset that anyone or group of persons would love to have on their team.  We recommend them as a great asset to any firm/organization with meaningful vision and focus",
  },
  {
    id: 3,
    position: "Director, Road2Recovery Foundation",
    testimony:
      "Very few notable brands such as LightVille, build sincere lasting relationships with their Clients. They go the extra mile to bring soothing and unique experiences to their clients of which we can solely attest to. They are excellent at mixing professionalism and socialism",
  },
]

const Testimonials = () => {
  const [pos, setPos] = useState(0)

  const navigateTo = id => {
    setPos(id)
  }

  const next = () => {
    pos >= testimonies.length - 1 ? setPos(0) : setPos(pos + 1)
  }

  const isActive = id => {
    if (id === pos) return "active"
  }

  const previous = () => {
    pos <= 0 ? setPos(testimonies.length - 1) : setPos(pos - 1)
  }

  return (
    <StyledTestimonial>
      <button className="prev" onClick={previous}>
        <img src={ChevronLeft} alt="left icon" />
      </button>

      <Container>
        <span className="text-caption">Testimonials</span>
        <p className="testimony">
          {testimonies[pos].testimony} -
          <span className="testifier">{testimonies[pos].position}</span>
        </p>

        <div className="navigators">
          {testimonies.map(testimony => (
            <button
              className={isActive(testimony.id)}
              onClick={() => navigateTo(testimony.id)}
            ></button>
          ))}
        </div>
      </Container>
      <button className="next" onClick={next}>
        <img src={ChevronRight} alt="right icon" />
      </button>
    </StyledTestimonial>
  )
}

export { Testimonials }
