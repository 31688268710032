import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { colors, spacing } from "../styles/theme"

export const StyledClickable = ({ variant }) => css`
  background-color: ${variant === "primary" ? colors.LVBlue : "white"};

  color: ${variant === "primary" ? colors.white : colors.LVBlue};

  text-decoration: none;
  border: 1px solid var(--lightville-blue);
  font: inherit;
  padding: ${`${spacing.spacing_sm}px 16px`};
  border-radius: 4px;
  display: inline-block;
  margin-right: 0.5em;
  box-shadow: 2px 4px 10px 0 rgba(102, 102, 102, 0.25);
  font-family: Poppins;
  transition: transform 0.25s;
  cursor: pointer;

  &:hover {
    background: ${variant === "primary" ? colors.LVBlue : "white"};

    box-shadow: 6px 8px 10px 0 rgba(102, 102, 102, 0.25);
  }

  &:hover,
  &:active,
  &:focus {
    transform: translateY(-2px);
  }
`

export const StyledLVLink = styled(Link)`
  ${StyledClickable}
`
