import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"

import { Nav, Container } from "../"
import StyledHeader from "./header-styles"

const PageHeader = ({ title, subTitle, hideTemplateParts }) => {
  return (
    <StyledHeader>
      <div className="header-container">
        <Nav />

        {!hideTemplateParts && (
          <Fade duration={350} bottom>
            <Container>
              <p className="text-caption">{subTitle}</p>
              <h1 className="centered">{title}</h1>
            </Container>
          </Fade>
        )}
      </div>
      <div className="custom-shape-divider-top-1596520536">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className="shape-fill-2"
          ></path>
        </svg>
      </div>
    </StyledHeader>
  )
}

PageHeader.propTypes = {
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hideTemplateParts: PropTypes.bool,
}

export default PageHeader
