import React from "react"
import StyledContainer from "./container-styles"
import PropTypes from "prop-types"

/**
 * Container for Wrapping Sections
 * @param width - Determines how wide the container is
 */

export const Container = ({
  width = "default",
  align = "center",
  ...props
}) => {
  return (
    <StyledContainer width={width} align={align} {...props}>
      {props.children}
    </StyledContainer>
  )
}

Container.propTypes = {
  width: PropTypes.oneOf(["narrow", "default"]),
  align: PropTypes.oneOf(["center", "left"]),
}
