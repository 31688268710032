import React from "react"
import PropTypes from "prop-types"

import StyledInput from "./input-styles"

/**
 * Renders an input element
 * @param onChange Function to run when input value changes
 * @param value Current value of input element
 * @param placeholder Text to show before data is entered
 * @param type Type of input
 */

const Input = ({ onChange, value, type = "text", placeholder, ...props }) => {
  return (
    <StyledInput
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      type={type}
      {...props}
    />
  )
}

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["number", "email", "text"]).isRequired,
}

export { Input }
