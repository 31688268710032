import styled from "@emotion/styled"
import Img from "gatsby-image"

import StyledFlex, { StyledFlexChild } from "../flex/flex-styles"
import StyledSection from "../section/section-styles"
import StyledButton from "../button/button-styles"

const StyledAvatarImage = styled(Img)`
  height: 9.4em;
  width: 9.4em;
  object-fit: none;
`

export const StyledTeam = styled(StyledSection)`
  margin-bottom: 2em;
`

export const StyledTeamContainer = styled(StyledFlex)`
  display: block;
  flex-wrap: wrap;
`

export const StyledTeamMember = styled(StyledFlexChild)`
  margin-right: 1em;
  flex: 0 1 20em;
  margin-bottom: 2em;
  font-size: 0.9em;

  ${StyledButton} {
    padding: 0.25em 0.5em;
    border: 1px solid #c2c2c2;
    box-shadow: none;
    font-size: 1em;
  }

  .bold {
    font-weight: 700;
  }

  @media (min-width: 759px) {
    font-size: 1em;
  }

  @media (min-width: 999px) {
    flex-basis: 25em;
    margin-bottom: 3em;
  }
`

export default StyledAvatarImage
