import styled from "@emotion/styled"

const StyledDivider = styled.hr`
  width: 9.4em;
  border: 0;
  margin: 3em auto;
  border-bottom: 2px solid var(--lightville-gold-dark);
`

export default StyledDivider
