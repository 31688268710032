import styled from "@emotion/styled"

const StyledContainer = styled.div`
  padding: 0 3%;
  max-width: ${({ width }) => (width === "narrow" ? "56em" : "78em")};
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 0 3.5%;
  }
`

export const StyledNarrowContainer = styled.div`
  padding: 0 3%;
  max-width: 53em;
  margin: 0 auto;
`

export default StyledContainer
