import React, { useContext } from "react"

import { StyledMobileNav } from "./nav-styles.js"
import { MenuContext } from "../layout/layout"
import { Container, LVLink } from "../../components"
import X from "../../images/icons/x.svg"

const MobileNav = () => {
  const modalContext = useContext(MenuContext)

  const { closeModal } = modalContext

  return (
    <StyledMobileNav>
      <Container>
        <div className="mobile-container">
          <ul>
            <li>
              <button onClick={closeModal}>
                <img src={X} aria-label="close-modal" />
              </button>
            </li>
            <li>
              <LVLink to="/" activeClassName="mobile-active" variant="vanilla">
                Home
              </LVLink>
            </li>
            <li>
              <LVLink
                to="/industries/"
                activeClassName="mobile-active"
                variant="vanilla"
              >
                Industries
              </LVLink>
            </li>
            <li>
              <LVLink
                activeClassName="mobile-active"
                to="/services/"
                variant="vanilla"
              >
                Services
              </LVLink>
            </li>
            <li>
              <LVLink
                activeClassName="mobile-active"
                to="/engagements/"
                variant="vanilla"
              >
                Engagements
              </LVLink>
            </li>
            <li>
              <LVLink
                activeClassName="mobile-active"
                to="/about/"
                variant="vanilla"
              >
                About
              </LVLink>
            </li>

            <li>
              <LVLink
                activeClassName="mobile-active"
                to="/contact-us/"
                variant="primary"
                className="active"
              >
                Contact Us
              </LVLink>
            </li>
          </ul>
        </div>
      </Container>
    </StyledMobileNav>
  )
}

export default MobileNav
