import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { StyledLVLink } from "./link-styles"

/**
 * Navigates to a page without reloading the browser. Renders a Gatsby Link component underneath
 * @param to The link to redirect to
 * @param variant Variant of Link to Render
 */

const LVLink = ({ children, to, variant = "primary", ...props }) => {
  if (variant === "vanilla")
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  return (
    <StyledLVLink to={to} variant={variant} {...props}>
      {children}
    </StyledLVLink>
  )
}

LVLink.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "vanilla"]),
}

export { LVLink }
