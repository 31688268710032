import styled from "@emotion/styled"
import { colors } from "../styles/theme"

const StyledHeader = styled.header`
  position: relative;

  .header-container {
    position: relative;
    background: ${() => colors.LVBlueLighter};
    z-index: 3;
    padding-bottom: var(--spacing-lg);
  }

  .custom-shape-divider-top-1596521134 {
    z-index: 1;
  }
  .custom-shape-divider-top-1596520536 {
    z-index: 2;
  }

  .custom-shape-divider-top-1596520536 svg {
    transform: rotateY(180deg);
  }

  .custom-shape-divider-top-1596521134 {
    position: absolute;
    top: 0;
    z-index: 2;
  }

  .custom-shape-divider-top-1596521134 svg {
    transform: rotateY(180deg);
    fill: #dfe7f2;
    height: 200px;
    width: calc(100% + 1.3px);
    height: 279px;
  }

  .custom-shape-divider-top-1596520536 .shape-fill-2 {
    fill: var(--lightville-blue-lighter);
  }

  .skewed {
    width: 100%;
    height: 100%;
    background: var(--lightville-blue-lighter);
    z-index: 0;
    transform: skewY(-4deg);
    transform-origin: top left;
  }
  /* .skewed-reversed {
    transform: skewY(80deg);
  } */
  .header-description {
    font-size: var(--zeta);
  }

  @media (max-width: 759px) {
    h1 {
      font-size: calc(var(--gamma) + 5px);
    }
  }

  @media (min-width: 759px) {
    h1 {
      text-align: center;
      max-width: 62em;
      margin: 0 auto var(--spacing-md);
    }
    .header-description {
      text-align: center;
      font-size: var(--epsilon);
      max-width: 34em;
      margin: 0 auto var(--spacing-md);
    }
    .btn-group {
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    .custom-shape-divider-top-1596520536 svg {
      width: calc(100% + 1.3px);
      height: 70px;
    }
  }
`

export default StyledHeader
