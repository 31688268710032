import styled from "@emotion/styled"

const StyledLogoContainer = styled.span`
  .lightville-logo {
    width: 2.5em;
    height: 2.5em;
    display: none;
  }
  /* .lightville-logo-large {
    display: none;
  } */

  @media screen and (min-width: 759px) {
    .lightville-logo {
      display: none;
    }
    .lightville-logo-large {
      /* display: block; */
      width: 7.2em;
    }
  }
`

export default StyledLogoContainer
