import React from "react"
import PropTypes from "prop-types"

import { StyledFlexChild } from "./flex-styles"

const FlexChild = ({ children }) => {
  return <StyledFlexChild>{children}</StyledFlexChild>
}

FlexChild.propTypes = {
  children: PropTypes.element.isRequired,
}

export { FlexChild }
