import React from "react"
import Fade from "react-reveal/Fade"

import { Container } from "../"
import StyledIndustriesSection, {
  StyledIndustryIcon,
  StyledGovtIcon,
} from "./industries-styles"
import Industry from "./industry"

import Book from "../../images/icons/book.svg"
import Briefcase from "../../images/icons/briefcase.svg"
import Chips from "../../images/icons/chips.svg"
import Dollar from "../../images/icons/dollar.svg"
import Hands from "../../images/icons/hands.svg"
import Government from "../../images/icons/balance.svg"
import { LVLink } from "../link/link"

const industries = [
  {
    icon: <StyledIndustryIcon src={Book} />,
    title: "Corporate Finance",
    description:
      "With LightVille saddled with Financial Analysts and Experts, we are able to share insights in the Finance ecosystem that helps our clients in financial decision making, measurement of value, investment guide, financial regulations and compliance, financial modelling etc.",
  },
  {
    icon: <StyledIndustryIcon src={Briefcase} />,
    title: "Business Management",
    description:
      "With the world experiencing exponential change dynamics in the global economy that drives its influence down to organizational behaviors and structures, systems and processes, a lot of frictions in businesses and organizations are erupting, leaving business owners, corporate leaders and entrepreneurs with so much burden. LightVille closes that gap by partnering with its clients in solving and fixing these frictions in a style of excellence, allowing you thrive and sail smoothly in the corporate world.",
  },
  {
    icon: <StyledIndustryIcon src={Chips} />,
    title: "Project Management",
    description:
      "Amongst our team of experts are globally recognized Project Management Professionals, set to help clients navigate through their projects seamlessly and achieve more wins than anticipated.",
  },
  {
    icon: <StyledIndustryIcon src={Dollar} />,
    title: "Technology",
    description:
      "LightVille is fully inclined with the present technological evolution and has incorporated technology in all of its processes as well as the experience we give to our clients. We leverage technology as part of our tool in offering unbeatable service deliveries. Amongst our team are technology experts in various categories tailored to solve specific frictions.",
  },
  {
    icon: <StyledIndustryIcon src={Hands} />,
    title: "Oil and Gas",
    description:
      "At LightVille, we have garnered years of experience in the oil and gas sector where a chunk of our client base resides. Contracting, Procurements, Projects deliverables consultations are some of our specialties as we have worked with both global and local Oil companies, helping them achieve their strategic objectives.",
  },
  {
    icon: <StyledGovtIcon src={Government} />,
    title: "Government",
    description:
      "With our team spread across the globe, we possess full insights as to the global economy, political insights across borders, global leadership patterns and trends. This serves as part of our arsenals in political and economic analyses, social analyses, institutional organizations and structuring amongst others.",
  },
]

const Industries = () => {
  return (
    <StyledIndustriesSection>
      <Container>
        <Fade>
          <div>
            <span className="text-caption">Industries</span>
            <h2 className="industries-heading">
              Industries We Currently Service
            </h2>
          </div>
        </Fade>

        <Fade>
          <section className="industries">
            {industries.map(industry => (
              <Industry
                title={industry.title}
                description={industry.description}
                icon={industry.icon}
              />
            ))}
          </section>
        </Fade>

        <LVLink to="/industries/" variant="secondary">
          See More About Industries
        </LVLink>
      </Container>
    </StyledIndustriesSection>
  )
}

export default Industries
