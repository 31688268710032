import React from "react"
import Fade from "react-reveal/Fade"

import { Nav, Container, LVLink } from "../"
import StyledHeader from "./header-styles"

const IndexHeader = () => {
  return (
    <StyledHeader>
      <div className="custom-shape-divider-top-1596521134">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className="shape-fill-1"
          ></path>
        </svg>
      </div>

      <div className="header-container">
        <Nav />

        <Fade duration={350}>
          <Container>
            <h1>
              One-Stop-Shop For{" "}
              <span className="accent">Premium Consultants.</span>
            </h1>
            <p className="header-description">
              <span className="accent-gold">LightVille</span> is a consulting
              Hub of Premium Consultants. Our goal is to help you fix frictions
              in your business and organisation in an excellent style
            </p>

            <div className="btn-group">
              <LVLink to="/contact-us/">Contact Us</LVLink>
              <LVLink to="/about/" variant="secondary">
                Learn More
              </LVLink>
            </div>
          </Container>
        </Fade>
      </div>
      <div className="custom-shape-divider-top-1596520536">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className="shape-fill-2"
          ></path>
        </svg>
      </div>
    </StyledHeader>
  )
}

export default IndexHeader
