import styled from "@emotion/styled"

const StyledFlex = styled.div`
  display: flex;
  flex-wrap: ${props => props.flexWrap};
`

export const StyledFlexChild = styled.div`
  flex: 1;
`

export default StyledFlex
