import React from "react"

import StyledDivider from "./divider-styles"
import { Container } from "../container/container"

const Divider = () => {
  return (
    <Container>
      <StyledDivider />
    </Container>
  )
}

export { Divider }
