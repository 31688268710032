import styled from "@emotion/styled"

import { spacing } from "../styles/theme"

const StyledSection = styled.section`
  padding: ${() => `${spacing.spacing_lg}px 0 ${spacing.spacing_md}px`};
  background: ${({ background }) =>
    background ? "var(--lightville-blue-lighter)" : null};
`

export default StyledSection
