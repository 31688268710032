import styled from "@emotion/styled"
import { spacing } from "../styles/theme"

const StyledFooter = styled.footer`
  background: #1a1a1a;
  color: #d9e4f3;
  font-family: poppins;
  font-size: 0.87em;
  padding: ${() => `0px 0 ${spacing.spacing_sm}px`};

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2em;
  }
  .footer-container__item {
    flex: 1 1 330px;
  }
  .footer-container__heading {
    display: flex;
    align-items: center;
    font-family: "Libre Baskerville";
    color: #fff;

    p {
      font-family: poppins;
      font-size: var(--delta);
      margin: 0;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: ${() => `${spacing.spacing_lg}px`};
  }
  li {
    margin-bottom: ${() => `${spacing.spacing_xs}px`};
  }
  a {
    color: #d9e4f3;
    text-decoration: none;
    display: inline-block;
  }
  .custom-shape-divider-top-1596347299 {
    background: var(--lightville-blue-lighter);
  }
  svg {
    margin-bottom: -10px;
  }
  .shape-fill {
    fill: #1a1a1a;
  }
  .logo-full {
    height: 2.5em;
    width: 2.5em;
    margin-right: 5px;
  }
  @media screen and (min-width: 1023px) {
    .footer-container__item.left {
      flex: 35%;
    }
    .footer-container__item.center_left,
    .footer-container__item.center_right {
      flex: 15%;
    }
  }
  .copyright {
    max-width: 31em;
    margin: 0 auto 1em;
  }
`

export default StyledFooter
