/**
 * Truncate long block of text
 * @param text Block of text to truncate
 * @param count Number length to truncate the string at
 * @returns {string} New text length
 */

const truncate = (text, count) => {
  if (text.length <= count) return text
  const words = text.split(" ")
  let wordsToReturn = []
  let wordCount = 0

  words.forEach(word => {
    if (wordCount >= count) return wordsToReturn.join(" ") + "..."

    wordCount = wordCount + word.length
    wordsToReturn.push(word)
  })

  return wordsToReturn.join(" ") + "..."
}

export default truncate
