import styled from "@emotion/styled"
import StyledSection from "../section/section-styles"

const StyledIndustriesSection = styled(StyledSection)`
  text-align: center;

  .industries {
    display: flex;
    flex-wrap: wrap;
  }

  .industries-heading {
    margin-bottom: var(--spacing-lg);
    max-width: 37rem;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 759px) {
    .industries-heading {
      margin-bottom: var(--spacing-xl);
    }
  }
`

export const StyledIndustry = styled.article`
  display: flex;
  text-align: left;
  flex: 18em;
  margin: 0 1em 1em 0;

  h3 {
    font-size: 1.25em;
  }
  .industry-icon {
    height: 2.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8em;
    background: var(--lightville-blue-lighter);
    text-align: center;
    border-radius: 100%;
    margin-right: 0.5em;
  }
  .industry-description {
    flex: 1;
  }
  h3,
  p {
    margin: 0;
  }
  h3 {
    margin-bottom: var(--spacing-xs);
  }
  p {
    margin-bottom: var(--spacing-sm);
    line-height: 1.5;
    font-size: 0.95em;
  }
`

export const StyledIndustryIcon = styled.img`
  height: 1.18em;
  display: block;
`

export const StyledGovtIcon = styled(StyledIndustryIcon)`
  height: 1.5em;
`

export default StyledIndustriesSection
