import React from "react"
import PropTypes from "prop-types"

import StyledButton from "./button-styles"

const Button = ({ children, variant = "primary", ...props }) => {
  return (
    <StyledButton variant={variant} {...props}>
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf("primary", "secondary", "vanilla"),
}

export { Button }
