export const colors = {
  LVBlue: "#2A6CCC",
  LVBlueDark: "#1D4B8E",
  LVBlueDarker: "#002460",
  LVBlueLight: "#5676BF",
  LVBlueLighter: "#EDF0F5",
  LVGold: "#F2A305",
  LVGoldDark: "#F2A305",
  LVGoldDarker: "#BA7400",
  LVGoldLight: "#FFD44A",
  LVGoldLighter: "#E5E2D8",
  greyOne: "#000000",
  greyTwo: "#333333",
  greyThree: "#808080",
  greyFour: "#E5E5E5",
  greyFive: "#F5F5F5",
  white: "#FFFFFF",
}
