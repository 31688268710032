import React from "react"

import StyledClients from "./client-styles"
import Client from "./client"
import { Container } from "../../components"

const Clients = ({ clientLogos }) => {
  const {
    cbn_logo,
    cad_logo,
    chaad_logo,
    r2r_logo,
    eyeson_logo,
    nlng_logo,
    nddc_logo,
    ssedc_logo,
  } = clientLogos

  const clients = [
    {
      logo: nlng_logo,
      name: "NLNG",
    },
    {
      logo: cbn_logo,
      name: "Central Bank of Nigeria",
    },
    {
      logo: cad_logo,
      name: "CAD Consulting LTD.",
    },
    {
      logo: chaad_logo,
      name: "CHAAD",
    },
    {
      logo: eyeson_logo,
      name: "Eyeson Dubia",
    },
    {
      logo: r2r_logo,
      name: "Road To Recovery",
    },

    {
      logo: nddc_logo,
      name: "Niger Delta Development Commission",
    },
    {
      logo: ssedc_logo,
      name: "South South Entrepreneurship Centre",
    },
  ]

  return (
    <StyledClients>
      <Container>
        <p className="centered">Clients We've Worked With</p>
        <div className="clients-container">
          {clients.map(client => (
            <Client
              key={client.name}
              clientName={client.name}
              logo={client.logo}
            />
          ))}
        </div>
      </Container>
    </StyledClients>
  )
}

export default Clients
