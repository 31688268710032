import React from "react"
import PropTypes from "prop-types"

import LightVilleLogo from "../../images/LightVile_Logo_Gold.png"
import LightVilleLogoLarge from "../../images/lightville-full.png"
import StyledLogoContainer from "./logo-styles"
import { LVLink } from "../link/link"

/**
 * Renders LightVille's Logo
 * @param variant The format the logo should render in
 */
const Logo = ({ variant, link, ...props }) => {
  if (link)
    return (
      <StyledLogoContainer variant={variant} {...props}>
        <LVLink to="/" variant="vanilla" className="flex">
          <img
            src={LightVilleLogo}
            alt="LightVille Logo"
            className="lightville-logo"
          />
          <img
            src={LightVilleLogoLarge}
            alt="LightVille Logo"
            className="lightville-logo-large"
          />
        </LVLink>
      </StyledLogoContainer>
    )

  return (
    <StyledLogoContainer variant={variant} {...props}>
      <img src={LightVilleLogo} alt="LightVille Logo" />
    </StyledLogoContainer>
  )
}

Logo.propTypes = {
  variant: PropTypes.oneOf(["logo", "logoText", "vanilla"]).isRequired,
  link: PropTypes.bool,
}

export { Logo }
