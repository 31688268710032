import React from "react"
import PropTypes from "prop-types"

import { StyledIndustry } from "./industries-styles"
import truncate from "../../../util/truncate"
/**
 * Renders all industries in a responsive grid
 */

const Industry = ({ title, icon, description }) => {
  return (
    <StyledIndustry>
      <div className="industry-icon">{icon}</div>
      <div className="industry-description">
        <h3>{title}</h3>
        <p>{truncate(description, 150)}</p>
      </div>
    </StyledIndustry>
  )
}

Industry.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
}

export default Industry
