import styled from "@emotion/styled"
import { css } from "@emotion/core"

export const StyledControl = () => css`
  padding: var(--spacing-xs);
  background: var(--lightville-blue-lighter);
  font-family: poppins;
  border: none;
  width: 100%;
  margin-bottom: var(--spacing-sm);
  transition: all 0.15s;
  outline: 1px;

  &:active,
  &:focus {
    background: #fff;
    outline: 2px solid var(--lightville-blue);
  }
`

const StyledInput = styled.input`
  ${StyledControl}
`

export default StyledInput
