import React from "react"
import { Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import StyledFooter from "./footer-styles"
import { Container } from "../../components"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query LogoFull {
      logo_full: file(relativePath: { eq: "LightVile_Logo_Gold.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { fluid } = data.logo_full.childImageSharp

  return (
    <StyledFooter>
      <div className="custom-shape-divider-top-1596347299">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <Container>
        <section className="footer-container">
          <article className="footer-container__item left">
            <ul>
              <li className="footer-container__heading">
                <Img fluid={fluid} alt="LightVille" className="logo-full" />
                <p>LightVille</p>
              </li>
              <li>Helping you fix frictions in your business.</li>
            </ul>
          </article>

          <article className="footer-container__item">
            <ul>
              <li className="footer-container__heading">CONTACT</li>
              <li>support@lightvilleconsulting.com</li>

              <li>+2348071156434, +2348166771851</li>

              <li>
                No 8 Chief Mba Street, Adantolu Estate. <br /> Agip/Ada George
                junction, <br /> Port Harcourt Rivers State, Nigeria.
              </li>
            </ul>
          </article>

          <article className="footer-container__item center_left">
            <ul>
              <li className="footer-container__heading">COMPANY</li>
              <li>
                <Link to="/about/">About Us</Link>
              </li>
              {/* <li>
                <Link to="/faq">FAQs</Link>
              </li> */}
              <li>
                <Link to="/contact-us/">Contact Us</Link>
              </li>
              <li>
                <Link to="/legal/">Legal</Link>
              </li>
              <li>
                <Link to="/industries/">Industries</Link>
              </li>
              <li>
                <Link to="/engagements/">Engagements</Link>
              </li>
              <li>
                <Link to="/services/">Services</Link>
              </li>
            </ul>
          </article>

          <article className="footer-container__item center_right">
            <ul>
              <li className="footer-container__heading">CONTACT</li>
              {/* <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.linkedin.com/in/lightville-consulting-0635a81b5/"
                >
                  LinkedIn
                </a>
              </li> */}
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.facebook.com/lightvilleconsulting"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://twitter.com/lightvillec"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://instagram.com/lightvilleconsult"
                >
                  Instagram
                </a>
              </li>
              {/* <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://youtube.com"
                >
                  YouTube
                </a>
              </li> */}
            </ul>
          </article>
        </section>

        <p className="centered copyright">
          © {new Date().getFullYear()} LightVille Consulting Services LTD.
          <br /> All Rights Reserved.
        </p>
      </Container>
    </StyledFooter>
  )
}

export default Footer
